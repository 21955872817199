/*-----------------------------------------*/
/*-----------------------------------------*/
/*---------- BASE MAIN JS FILE -----------*/
/*-----------------------------------------*/
/*-----------------------------------------*/
$.fn.restrict = function( chars ) {
    return this.keydown(function(e) {
        var found = false, i = -1;
        while(chars[++i] && !found) {
            found = chars[i] == String.fromCharCode(e.which).toLowerCase() || 
                    chars[i] == e.which;
        }
        if (!found) { e.preventDefault(); }
    });
};  

$(document).ready(function(){

	if($('#changeArea').length > 0){
		if ($(window).width() < 1000) {
		   	$('#changeArea .changeSteps .lastStep').insertBefore('#endStep');
		}
		else {
			  $('#changeArea .changeSteps .lastStep').insertBefore('#changeArea .changeSteps .changeStep2');
		}
	}
	sliderVideoHeight();

	$(window).on('resize', function(){
		sliderVideoHeight();
	});

	function sliderVideoHeight() {
		if ($('.main-slider .youtube-container').length>0) {
			var height = $('.slide1 img:visible').height(),
				youtubeContainerHeight  = height;
				videoHeight = youtubeContainerHeight - 80,
				videoWidth = videoHeight * 1.777083;
			$('.main-slider .youtube-container').attr('style', 'height: '+youtubeContainerHeight+'px;');
			$('iframe.youtube').attr('style', 'height: '+videoHeight+'px; width: '+videoWidth+'px;');
		}
		if ($('#main .youtube-container').length>0) {
			var width =$('#main .youtube-container').width(),
				height = width * 0.56279;
			$('iframe.youtube').attr('style', 'height: '+height+'px; width: '+width+'px;');

		}
	}

	$('body').on('click', 'a.toggle', function() {
		var trg = $(this).data('target');
		if ($('#'+trg).length>0) {
			$('#'+trg).addClass('open');
		}
	});

	$('body').on('click', '.modal .close', function() {
		closeModal();
	});

	function closeModal() {
		$('.modal').removeClass('open');
	}



////Initialize Foundation
	$(document).foundation();

	if($('.body_frame').length > 0){
		$('.body_frame').restrict(['x','y','z',8]);
		$('.body_depth').restrict(['s','m','l',8]);
		$(".breast_volume").on("keypress keyup blur",function (event) {    
	     $(this).val($(this).val().replace(/[^\d].+/, ""));
	      if ((event.which < 48 || event.which > 57)) {
	          event.preventDefault();
	      }
	  });
	}

	// Mobile Menu
	if ($("header").width() <= 1024) {
	   	$( "header nav .fa-bars, header ul.menu li a" ).click(function() {
		  $( "header ul.menu" ).slideToggle( "slow" );
		});
	}
	else {
	    eventFired = 1;
	}
	$(window).on('resize', function() {

		if($('#changeArea').length > 0){
			if ($(window).width() < 980) {
			   	$('#changeArea .changeSteps .lastStep').insertBefore('#endStep');
			}
			else {
				  $('#changeArea .changeSteps .lastStep').insertBefore('#changeArea .changeSteps .changeStep2');
			}
		}

	    if (eventFired == 1) {
	        if ($("header").width() <= 1024) {
			   	$( "header nav .fa-bars, header ul.menu li a" ).click(function() {
				  $( "header ul.menu" ).slideToggle( "slow" );
				});
	    		eventFired = 0;
	    	} else {
	    		eventFired = 1;
	        }
	    }
	});
	
	$('.main-slider').slick({
		autoplay: true,
		arrows: false,
		dots: false
	});

	$('.testimonial-slider').slick({
		autoplay: false,
		arrows: false,
		dots: false
	});
	$('.testPrev').click(function(){
	   $(".testimonial-slider").slick('slickPrev');
	});
	$('.testNext').click(function(){
	   $(".testimonial-slider").slick('slickNext');
	});

	// Get base url
	var baseurl = document.getElementById('baseurl').value;

	// Contact Page Map
	if($('.map').length > 0){
		// Google Maps
		function initialize() {
		var imagePath = baseurl + '/wp-content/themes/optifit/img/mapIcon.png';
		var mapOptions = {
		  center: { lat: 53.548363, lng: -2.005454 },
		  zoom: 18,
		  panControl: true,
		  zoomControl: true,
		  draggable: true,
		  scrollwheel: false,
		  mapTypeControl: false,
		  scaleControl: false,
		  streetViewControl: true,
		  overviewMapControl: true,
		  styles: [{"featureType":"road","stylers":[{"hue":"#5e00ff"},{"saturation":-79}]},{"featureType":"poi","stylers":[{"saturation":-78},{"hue":"#6600ff"},{"lightness":-47},{"visibility":"off"}]},{"featureType":"road.local","stylers":[{"lightness":22}]},{"featureType":"landscape","stylers":[{"hue":"#6600ff"},{"saturation":-11}]},{},{},{"featureType":"water","stylers":[{"saturation":-65},{"hue":"#1900ff"},{"lightness":8}]},{"featureType":"road.local","stylers":[{"weight":1.3},{"lightness":30}]},{"featureType":"transit","stylers":[{"visibility":"simplified"},{"hue":"#5e00ff"},{"saturation":-16}]},{"featureType":"transit.line","stylers":[{"saturation":-72}]},{}]
		};

		var contentString = '<h3>The Bra Spa</h3>'+
	        '<p>Optifit Bra Company Ltd<br/>'+
	        'Suite 1, Globe Chambers<br/>'+
			'76, High Street<br/>'+
			'Uppermill, Saddleworth<br/>'+
	        'OL3 6AW</p>'+
	        '<a class="event" href="https://www.google.com/maps/place/76+High+Street+Uppermill+Saddleworth+OL3+6AW" target="_blank">See full map</a> <a class="event" href="https://maps.google.com?daddr=76+High+Street+Uppermill+Saddleworth+OL3+6AW" target="_blank">Get directions</a>';

		var markers = [
		  ['Optifit', 53.548363, -2.005454]
		];

		var map = new google.maps.Map(document.getElementById('map'),
		    mapOptions);

		var infowindow = new google.maps.InfoWindow(), marker, i;
		for (i = 0; i < markers.length; i++) {  
		    marker = new google.maps.Marker({
		        position: new google.maps.LatLng(markers[i][1], markers[i][2]),
		        icon: imagePath,
		        map: map
		    });
		    google.maps.event.addListener(marker, 'click', (function(marker, i) {
		        return function() {
		            infowindow.setContent(contentString);
		            infowindow.open(map, marker);
		        }
		    })(marker, i));
		    infowindow.setContent(contentString);
		}
		google.maps.event.addDomListener(window, "resize", function() {
			var center = map.getCenter();
			google.maps.event.trigger(map, "resize");
			map.setCenter(center);
			});

		}
		google.maps.event.addDomListener(window, 'load', initialize);
	}

	// Benefits information popups 
	$( ".changeStep i.fa" ).click(function() {
	  $(this).next().slideToggle( "slow" );
	});

});